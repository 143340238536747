import AppOptions from './config/AppOptions'
import { createI18n } from 'vue-i18n'


function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  return locales.keys().reduce((acc, key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      const locale = matched[1]
      acc[locale] = locales(key)
    }

    return acc
  }, {})
}

const i18n = createI18n({
  locale: (window.appOptions ? window.appOptions.locale.locale : AppOptions.locale.locale) || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
})

export default i18n
