<template>
  <q-layout
    view="hHh LpR fFf"
    :class="appOptions.theme || ''"
  >
    <div
      class="row justify-center items-center q-py-md"
      style="min-height: inherit;"
    >
      <div
        class="text-center"
        :style="`max-width: ${maxWidth}px;`"
      >
        <div class="q-mb-md hide-on-mobile">
          <img
            :src="appOptions.securityLogo"
            style="max-width: 100%; max-height: 200px; object-fit: contain;"
          >
        </div>

        <q-page-container class="full-width">
          <router-view />
        </q-page-container>
      </div>
    </div>
  </q-layout>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      appOptions: this.$appOptions,
      currentBackground: 'assets/img/login-bg/login-bg-17.jpg',
      isWorkspacesEnable: window.isWorkspacesEnable,
      subscriber: null
    }
  },
  computed: {
    maxWidth () {
      if (this.$q.screen.width < 500) {
        return this.$q.screen.width - 20
      }

      return 400
    }
  },
  mounted () {
    this.subscriber = this.$eventBus.subscribe('rules', data => {
      this.appOptions = { ...data.appOptions }
    })
  },
  unmounted () {
    this.subscriber.unsubscribe()
  }
}
</script>
