export class Service {
  _httpClient;
  _url;
  _middleware;


  constructor (url, httpClient, middleware) {
    this._url = url
    this._httpClient = httpClient
    this._middleware = middleware
  }

  /**
   * @param {String | Number} id
   * @param {object} get
   * @param {String} accept
   *
   * @returns {Promise<object>}
  */
  get (id, get, accept) {
    return this._httpClient.get(`${this._url}/${id}`, get, accept)
  }

  /**
   * @param {object} query
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
  getAll (query, accept) {
    return this._httpClient.get(this._url, query, accept)
      .then(this._middleware)
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
  save (data, id, headers, query = {}) {
    return this._httpClient.saveRequest(this._url, data, id, undefined, undefined, headers, query)
  }
}
