import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import './../../assets/css/style.css'

import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'

import i18n from './../../i18n'
import { UserService } from './services/user.service'
import Quasar from 'quasar/src/vue-plugin.js';
import services from './services'
import FormBuilder from './../../packages/form-builder'

export function bootstrap (root, eventBus, options) {
  const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes: [
      {
        path: '/login',
        component: () => import('./pages/Login.vue')
      },
      {
        path: '/register',
        component: () => import('./pages/Register.vue')
      },
      {
        path: '/:pathMatch(.*)*',
        redirect: '/login'
      }
    ]
  })

  const app = createApp(App)
    .use(router)
    .use(i18n)
    .use(Quasar, {
      config: {
        animations: 'all'
      }
    })
    .use(FormBuilder)

  app.config.globalProperties.$userService = UserService
  app.config.globalProperties.$eventBus = eventBus
  app.config.globalProperties.$appOptions = options.appOptions
  app.config.globalProperties.$defaultAppOptions = options.defaultAppOptions
  app.config.globalProperties.$service = services
  
  return app
}
