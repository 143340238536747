import { defineAsyncComponent } from 'vue'

/**
 * Example of permissions format
 */
// {
//   "Orderadmin\DeliveryServices\Entity\DeliveryRequest": {
//     "global": {
//       "read": true,
//       "write": true
//     },
//     "states": [
//       {
//         "states": ["pending", "pre_processing"],
//         "fields": {
//           "state": {
//             "permissions": ["read", "write"],
//             "options": {
//               "required": true,
//               "values": ["sent", "error", "pending_queued"],
//             }
//           }
//         }
//       }
//     ]
//   }
// }
export default {
  install (app, options = {}) {
    const fullAccess = {
      read: true,
      write: true,
      options: {}
    }

    let permissions = options.permissions || {}

    function getFields (data, state) {
      const value = data.states.find(x => x.states.includes(state))

      if (!value) {
        return {}
      }

      return value.fields
    }
    
    app.config.globalProperties.$formBuilder = {
      fullAccess,
      getFields,
      setPermissions (data) {
        permissions = data
      },
      getAccess (entity, state, field) {
        const data = permissions[entity]

        if (!data) {
          return fullAccess
        }

        if (data.global && data.global.read && !data.global.write) {
          return {
            read: true,
            write: false,
            options: {}
          }
        }

        const fields = getFields(data, state)

        if (!fields[field]) {
          return fullAccess
        }

        return {
          write: !!fields[field].permissions.includes('write'),
          read: !!fields[field].permissions.includes('read'),
          options: fields[field].options || {}
        }
      }
    }

    app.component('form-builder', defineAsyncComponent(() => import('./FormBuilder.vue')))
  }
}
