/**
 * Create Items Data
 *
 * @param {Object} data
 *
 * @returns {Object}
 */
export function createItemsData (data) {
  const keys = Object.keys(data._embedded)

  return {
    items: data._embedded[keys[0]],
    totalItems: data.total_items,
    page: data.page,
    totalPages: data.page_count
  }
}
